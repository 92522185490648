import { helpers } from '@common'
import { store } from '@pc/store'

import { globalActionCreator } from '@pc/common/globalActions'
import axios from 'axios'
import { push } from 'connected-react-router'
import { creditStatusMap, newCreditStatusMap } from '@pc/common/macro'
import { Translate } from '@pc/config/translate'
import { actionCreator as mainActionCreator } from '../../main/store'
import Logger from '@pc/config/logger'
import loggerConfig from '../logger.config'

const { get, storage, URL } = helpers
/*
 * 保存订单信息到localstorage
 * 为了兼容老版本菲律宾授信
 */
export const goSaveOrderParam = (curPeriod, countryCode) => {
  const state = store.getState()
  const appId = state.getIn(['main', 'appId'])
  const refNo = state.getIn(['main', 'refNo'])
  const sign = state.getIn(['main', 'sign'])
  const creditStatus = state.getIn(['orderDetail', 'creditStatus'])
  const { phone } = URL.allParam()

  return (dispatch) => {
    if (countryCode === 'PH' && (creditStatus === 2 || creditStatus === 4)) {
      storage.set('orderParam', { appId, refNo, sign, period: curPeriod })
      storage.set('phoneNumber', phone)
    } else {
      storage.remove('orderParam')
      storage.remove('phoneNumber')
    }
  }
}

/**
 * 检查用户授信状态新
 * */

const __checkUserCreditStatusNew = (curPeriod) => {
  return async (dispatch, getState) => {
    const state = getState()
    const appId = state.getIn(['main', 'appId'])
    const refNo = state.getIn(['main', 'refNo'])
    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { data },
    } = await axios.post(
      '/capi/openpay/user/info',
      { appId, refNo },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    dispatch(globalActionCreator.toggleLoading(false))
    return data.status
  }
}

const __checkUserCreditStatusOld = (curPeriod) => {
  return async (dispatch, getState) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { data, success, errCode },
    } = await axios.get('/api/json/public/openpay/user/status')
    dispatch(globalActionCreator.toggleLoading(false))
    return data
  }
}

/*
 * 检查用户授信状态
 */
export const checkUserCreditStatus = (curPeriod) => {
  const translate = Translate.getInstance().translate

  return async (dispatch) => {
    const state = store.getState()
    const data = await dispatch(__checkUserCreditStatusNew())

    if (data === newCreditStatusMap.CREDIT_STATUS_APPLIED) {
      dispatch(
        push({
          pathname: './orderDetails',
          state: { curPeriod: '0' },
          search: `${window.location.search}`,
        }),
      )
    } else {
      dispatch(
        globalActionCreator.toggleAlertDialog(true, {
          title: translate('提示'),
          content: translate(
            '您的申请正在加速审核中，请稍等；审核预计会在半小时内完成。',
          ),
          footer: [
            {
              text: translate('我知道了'),
              onPress: () => {},
            },
          ],
        }),
      )
    }
  }
}

export const goLogOut = () => {
  return async (dispatch) => {
    await dispatch(globalActionCreator.globalLogOut())
    // 切量完成之后需要替换回来下面的方法
    // logoutToOldSys()
    // const { canModifyPhoneNumber, showBindProtocol } = data
    // dispatch(
    //   mainActionCreator.goSetCanModifyPhoneNumber(canModifyPhoneNumber)
    // )
    // dispatch(mainActionCreator.goSetShowBindProtocol(showBindProtocol))
  }
}
export const downloadApp = () => {
  return async (dispatch) => {
    const saLogger = Logger.getInstance()
    // saLogger.onPageClick(loggerConfig.download)
    saLogger.onPageClick(loggerConfig.download_btn)
    const translate = Translate.getInstance().translate
    dispatch(
      globalActionCreator.toggleToast(
        true,
        translate('Akulaku应用程序下载链接已通过短信发送到您的手机。'),
      ),
    )
    await axios.post(
      `/capi/openpay/app/download/link/sms/send`,
      { type: 1 },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  }
}
