import React, { PureComponent } from 'react'
import { helpers, withBasic } from '@common'
import moduleName from '../../../orderDetails/components/CouponItem'

import styles from './style.m.scss'
import CouponItem from '../../../orderDetails/components/CouponItem'
import BizTracker from '@pc/common/tracker/bizTracker'
import { Map } from 'immutable'

const className = helpers.classNames.react(styles)
const { storage, URL } = helpers

@withBasic
@helpers.hot(module)
export class CreditLimitCard extends PureComponent {
  state = {
    couponItem: Map({
      couponId: 1,
      name: '优惠券1',
      faceAmount: 1000,
      discountType: 1,
      discount: 300,
      startDate: '2020 08 02',
      endDate: '2020 10 02',
      couponType: 5,
    }),
  }

  componentDidMount() {
    BizTracker.enter({ sn: 390012 })
  }

  componentWillUnmount() {
    BizTracker.leave()
  }

  render() {
    const { translate, formatPrice, currencyCode, platform, countryCode } =
      this.props

    const { couponItem } = this.state

    return (
      <div
        {...className('credit-card-box font-lato-medium', {
          'card-pc': platform === 'pc',
          'card-m': platform !== 'pc',
        })}
      >
        {/* <p {...className('main_tip font-lato-bold')}>
          {translate('恭喜你获得一张优惠券')}
        </p>
        <p {...className('sub_tip font-lato-reg')}>
          {translate('优惠券已经放入你的账号')}
        </p>
        <div {...className('coupon_box')}>
          <CouponItem
            translate={translate}
            platform={platform}
            couponItem={couponItem}
          />
        </div> */}
        <p>{translate('最高额度')}</p>
        <p>
          <span {...className('font-lato-bol')}>{currencyCode}</span>
          {countryCode === 'ID' ? (
            <span {...className('font-grotesk-bold')}>
              {formatPrice('25000000')}
            </span>
          ) : (
            <span {...className('font-grotesk-bold')}>
              {formatPrice('50000')}
            </span>
          )}
        </p>
      </div>
    )
  }
}
