import React, { PureComponent, Fragment } from 'react'
import { helpers, withBasic } from '@common'
import { Radio } from 'antd-mobile'
import Icon from '@pc/components/Icon'
import { Button } from '@pc/components/Button'
import dayjs from 'dayjs'

import styles from './style.m.scss'

import Icon_ecommerce from '@pc/assets/img/coupon_icon_ecommerce.png'
import Icon_financial from '@pc/assets/img/coupon_icon_financial.png'
import Icon_interest from '@pc/assets/img/coupon_icon_interest.png'
import Icon_loan from '@pc/assets/img/coupon_icon_loan.png'
import Icon_openpay from '@pc/assets/img/coupon_icon_openpay.png'

import { COUPON_TYPE, COUPON_DISCOUNT_TYPE } from '@pc/common/macro'

const className = helpers.classNames.react(styles)

@withBasic
export default class CouponItem extends PureComponent {
  // 优惠券类型对应的icon
  couponTypeIcon = {
    [COUPON_TYPE.COUPON_ECOMMERCE]: Icon_ecommerce,
    [COUPON_TYPE.COUPON_FINANCIAL]: Icon_financial,
    [COUPON_TYPE.COUPON_INTEREST]: Icon_interest,
    [COUPON_TYPE.COUPON_LOAN]: Icon_loan,
    [COUPON_TYPE.COUPON_OPENPAY]: Icon_openpay,
    [COUPON_TYPE.COUPON_OPENPAY_INTEREST]: Icon_interest,
  }

  makeCounponImage = (couponType, operateType) => {
    const { isDisabled } = this.props
    const imgMap = isDisabled
      ? this.couponTypeDisableImage
      : this.couponTypeImage
    return <Icon icontype="image" src={imgMap[couponType]} />
  }

  makeTitle = (item) => {
    const couponType = item.get('couponType')
    const name = item.get('name')
    const { translate } = this.props

    return (
      <span {...className('coupon_title')}>
        <Icon
          icontype="image"
          src={this.couponTypeIcon[couponType]}
          {...className('icon-coupon')}
        />
        <span>{name}</span>
      </span>
    )
  }

  makeOperatingArea = () => {
    const { operateType, translate, isChecked, onSelect, couponItem } =
      this.props

    if (operateType === 'use') {
      return (
        <span
          {...className('operate_use operate_item')}
          onClick={() => onSelect(couponItem.get('couponId'), !isChecked)}
        >
          {translate('使用')}
          <Icon
            {...className('icon_check')}
            icontype="svg"
            name={
              isChecked ? 'registered_ico_choose_noselected3x' : 'iconNocheck'
            }
          />
        </span>
      )
    }

    if (operateType === 'disabled') {
      return (
        <span {...className('operate_item item_disabled')}>
          {translate('不可用')}
        </span>
      )
    }

    if (operateType === 'collected') {
      return <span {...className('operate_item')}>{translate('已领取')}</span>
    }

    if (operateType === 'receive') {
      return (
        <Button
          {...className('receive-btn')}
          text={translate('领取')}
          size="small"
          onClick={() => {}}
        />
      )
    }

    return null
  }

  // 格式化时间
  formatTime = (timestamp) => {
    if (timestamp) {
      return dayjs(timestamp).format('HH:mm:ss DD/MM/YYYY')
    }
    return ''
  }

  getCouponClass = () => {
    const { couponItem, isDisabled } = this.props
    const couponType = couponItem.get('couponType')
    const couponClassMap = {
      [COUPON_TYPE.COUPON_ECOMMERCE]: {},
      [COUPON_TYPE.COUPON_FINANCIAL]: {},
      [COUPON_TYPE.COUPON_OPENPAY_INTEREST]: {
        coupon_interest: true,
        coupon_interest_disabled: isDisabled,
      },
      [COUPON_TYPE.COUPON_LOAN]: {},
      [COUPON_TYPE.COUPON_OPENPAY]: {},
    }
    return couponClassMap[couponType]
  }

  render() {
    const {
      couponItem,
      translate,
      isDisabled,
      hasDetailIcon,
      onDetailClick,
      platform,
      operateType,
      formatPrice,
    } = this.props

    const cdkCode = couponItem.get('cdkCode')

    return (
      <div
        {...className('coupon-container', {
          'coupon-disabled': isDisabled,
          'coupon-pc-style': platform === 'pc',
        })}
      >
        <div
          {...className('coupon-itself', {
            coupon_itself_disabled: isDisabled,
            ...this.getCouponClass(),
          })}
        >
          <div {...className('coupon-detail')}>
            <div {...className('detail-title')}>
              {this.makeTitle(couponItem)}
              {cdkCode ? (
                <div {...className('coupon_cdk')}>{cdkCode}</div>
              ) : null}
            </div>
            <div>
              <p {...className('detail-amount font-din-bold')}>
                {couponItem.get('discountType') ===
                COUPON_DISCOUNT_TYPE.DISCOUNT
                  ? translate('减免{num}%', {
                      num: couponItem.get('faceAmount'),
                    })
                  : formatPrice(couponItem.get('faceAmount'))}
              </p>
              <p {...className('detail-desc')}>
                {couponItem.get('description')}
              </p>
            </div>
          </div>
          <div
            {...className('coupon_expired', {
              display_none: !couponItem.get('expire'),
            })}
          >
            {translate('即将过期')}
          </div>
        </div>
        <div {...className('coupon-bottom')}>
          <div>
            {operateType === 'disabled' ? (
              <p {...className('disable_reason_txt')}>
                {couponItem.get('unAvailableReason')}
              </p>
            ) : (
              <p>
                {translate('开始时间')}:{' '}
                {this.formatTime(couponItem.get('startDate'))}
              </p>
            )}
            <p>
              {translate('过期时间')}:{' '}
              {this.formatTime(couponItem.get('endDate'))}
            </p>
          </div>
          {this.makeOperatingArea()}
        </div>

        {hasDetailIcon ? (
          <div {...className('detail-icon-wrapper')} onClick={onDetailClick}>
            <Icon icontype="svg" name="detail" {...className('icon-detail')} />
          </div>
        ) : null}
      </div>
    )
  }
}
