import React, { PureComponent } from 'react'
import { helpers, withBasic } from '@common'
import { Button } from '@pc/components/Button'
import Logger from '@pc/config/logger'
import ApplySteps from '@pc/components/ApplySteps'

import styles from './style.m.scss'

import Icon from '@pc/components/Icon'
import icon_tips from '@pc/assets/img/tips@3x.png'
import { actionCreator } from '@pc/views/applylimit-instruction/store'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const className = helpers.classNames.react(styles)
const { storage, URL } = helpers

@connect(
  () => ({}),
  (dispatch) => {
    return bindActionCreators(
      {
        downloadApp: actionCreator.downloadApp,
      },
      dispatch,
    )
  },
)
@withBasic
@helpers.hot(module)
export class CreditInstruction extends PureComponent {
  constructor() {
    super()
    this.saLogger = Logger.getInstance()
  }

  render() {
    const { translate, formatPrice, currencyCode } = this.props
    const { onApplyClick, onPlaceOrderClick, platform, checkUserCreditStatus } =
      this.props

    return (
      <div
        {...className('apply-process-box', {
          'pc-process-box': platform === 'pc',
        })}
      >
        <div {...className('apply-tips')}>
          <Icon icontype="image" src={icon_tips} {...className('tips-icon')} />
          <span>{translate('通过授信后可使用优惠码进行支付')}</span>
        </div>
        <div {...className('apply_step_container')}>
          <ApplySteps platform={platform} translate={translate} />
        </div>

        <div {...className('apply-process-bottom')}>
          <Button
            text={translate('下载Akulaku')}
            size="large"
            onClick={this.props.downloadApp}
          />
        </div>
        {/* <p>{translate('仅需三步即可获得额度')}</p>
        <PaymentProcess type="applylimits" platform={platform} />
        <div {...className('apply-tips')}>
          <Icon icontype="image" src={icon_tips} {...className('tips-icon')} />
          <span>{translate('通过授信后可使用优惠码进行支付')}</span>
        </div> */}
        {/* <div {...className('apply-process-bottom')}>
          <Button
            text={translate('申请分期额度')}
            size="large"
            onClick={() => {
              onApplyClick()
              this.saLogger.onPageClick(
                elementId.CONTINUE_APPLY_LIMIT,
                null,
                elementId.PAGE_REGISTER_SUCCESS
              )
            }}
          />
          <Button
            text={translate('我要全款支付')}
            size="large"
            hollow={true}
            onClick={() => {
              onPlaceOrderClick()
              this.saLogger.onPageClick(
                elementId.CONTINUE_PAYMENT,
                null,
                elementId.PAGE_REGISTER_SUCCESS
              )
            }}
          />
        </div> */}
      </div>
    )
  }
}
