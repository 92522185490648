export default {
  // 全款用户引导页
  page_view: {
    Aku_page_id: 'openpay02',
    Aku_page_name: 'full user guide page',
  },
  // 点击复制优惠码
  copy_code: {
    Aku_page_id: 'openpay02',
    Aku_page_name: 'full user guide page',
    Aku_element_id: 'openpay020101',
    Aku_element_name: 'copy code',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'guide',
    Aku_position_id: '01',
  },
  // 点击刷新授信状态
  fresh_credit_status: {
    Aku_page_id: 'openpay02',
    Aku_page_name: 'full user guide page',
    Aku_element_id: 'openpay020102',
    Aku_element_name: 'fresh credit status',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'guide',
    Aku_position_id: '02',
  },
  // 下载按钮
  download: {
    Aku_page_id: 'openpay14',
    Aku_page_name: 'openpay register success page',
    Aku_element_id: 'openpay140104',
    Aku_element_name: 'download',
    Aku_moudle_id: '01',
    Aku_moudle_name: '',
    Aku_position_id: '04',
  },
  download_btn: {
    Aku_page_id: 'openpay11',
    Aku_element_id: 'openpay110101',
  },
  logout: {
    Aku_page_id: 'openpay14',
    Aku_page_name: 'openpay register success page',
    Aku_element_id: 'openpay140105',
    Aku_element_name: 'log out',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'register ',
    Aku_position_id: '05',
  },
}
