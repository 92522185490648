import React, { PureComponent } from 'react'
import { helpers } from '@common'
import styles from './style.m.scss'
import Icon from '../Icon'

import icon_1 from './img/ico_personal备份@2x.png'
import icon_2 from './img/ico_occupation备份@2x.png'
import icon_3 from './img/ico_ktpphoto备份@2x.png'

const className = helpers.classNames.react(styles)

export default class ApplySteps extends PureComponent {
  constructor(props) {
    super()
    this.state = {
      activeValue: props.activeValue,
    }
  }

  onTabClick = (value) => {
    this.setState({
      activeValue: value,
    })
    this.props.onChoose(value)
  }

  render() {
    const { tabList, onChoose, platform } = this.props
    const { translate } = this.props

    return (
      <div
        {...className('apply-steps-container', {
          pc_style: platform === 'pc',
        })}
      >
        <div {...className('apply-step-item')}>
          <Icon icontype="image" src={icon_1} {...className('item-icon')} />
          <div>
            <p>{translate('第一步')}</p>
            <p>{translate('下载并打开Akulaku APP')}</p>
          </div>
        </div>
        <div {...className('vertical-dash')} />
        <div {...className('apply-step-item')}>
          <Icon icontype="image" src={icon_2} {...className('item-icon')} />
          <div>
            <p>{translate('第二步')}</p>
            <p>{translate('登录并完成授信')}</p>
          </div>
        </div>
        <div {...className('vertical-dash')} />
        <div {...className('apply-step-item')}>
          <Icon icontype="image" src={icon_3} {...className('item-icon')} />
          <div>
            <p>{translate('第三步')}</p>
            <p>{translate('获取信用额度后可进行分期付款')}</p>
          </div>
        </div>
      </div>
    )
  }
}
